import React from "react";
import "./Loader.scss";
const Loader = () => {
  return (
    <div className="loader">
      {/* <figure>
        <div class="dot white"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </figure> */}
      <svg
        width="80"
        height="80"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.9865 26.5453C42.4178 33.0714 41.6216 42.1789 41.4988 43.365L32.3768 43.2514C32.5997 40.9973 32.6179 33.9303 29.9155 30.9445C29.3604 30.3355 28.4869 29.6356 26.6261 29.6356H0.625V20.5463H28.9327C32.0947 20.5463 34.6653 17.9786 34.6653 14.8201C34.6653 11.6616 32.0947 9.08929 28.9327 9.08929H0.625V0H28.9327C37.1084 0 43.7645 6.64882 43.7645 14.8201C43.7645 19.5829 41.4988 23.8321 37.9865 26.5453Z"
          fill="#D82F4A"
        />
      </svg>
    </div>
  );
};

export default Loader;
