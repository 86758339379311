// import logo from "../Asset/Logo.svg";
// import DoctorLogo from "../Asset/DoctorEquip.svg";
// import PatientLogo from "../Asset/PatientLogo.svg";
// import SVHlogo from "../Asset/SVHlogo.svg";
// import LandscapeIcon from '../Asset/LandscapeIcon.svg'

// export const StoreImages = {
//   DoctorImg: require("../Asset/DoctorImg.png"),
//   logo: logo,
//   DoctorLogo: DoctorLogo,
//   PatientLogo: PatientLogo,
//   SVHlogo :SVHlogo,
//   LandscapeIcon: LandscapeIcon,
// };

export function UserIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M7.5 6.5C7.5 8.981 9.519 11 12 11C14.481 11 16.5 8.981 16.5 6.5C16.5 4.019 14.481 2 12 2C9.519 2 7.5 4.019 7.5 6.5ZM20 21H21V20C21 16.141 17.859 13 14 13H10C6.14 13 3 16.141 3 20V21H20Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
}

export function EmailIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
}

export function LockIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <path
          d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
          fill="#333333"
        />
      </g>
    </svg>
  );
}

export function UserOutlined() {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.947 13.332C21.219 11.915 22 10.049 22 8C22 3.589 18.411 0 14 0C9.589 0 6 3.589 6 8C6 12.411 9.589 16 14 16C15.555 16 17.003 15.547 18.233 14.776C22.583 16.415 25.578 20.396 25.959 25H2.042C2.301 21.901 3.755 19.011 6.12 16.949C6.537 16.586 6.58 15.955 6.217 15.538C5.855 15.122 5.223 15.078 4.806 15.441C1.751 18.103 0 21.951 0 26C0 26.553 0.448 27 1 27H27C27.553 27 28 26.553 28 26C28 20.514 24.82 15.615 19.947 13.332ZM8 8C8 4.691 10.691 2 14 2C17.309 2 20 4.691 20 8C20 11.309 17.309 14 14 14C10.691 14 8 11.309 8 8Z"
        fill="white"
      />
    </svg>
  );
}

export function DashboardIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill="#FBEEF0" />
      <g opacity="1">
        <path
          d="M11.3335 14.6666H12.6668C14.0002 14.6666 14.6668 13.9999 14.6668 12.6666V11.3333C14.6668 9.99992 14.0002 9.33325 12.6668 9.33325H11.3335C10.0002 9.33325 9.3335 9.99992 9.3335 11.3333V12.6666C9.3335 13.9999 10.0002 14.6666 11.3335 14.6666Z"
          fill="#CA2543"
          stroke="#CA2543"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3335 14.6666H20.6668C22.0002 14.6666 22.6668 13.9999 22.6668 12.6666V11.3333C22.6668 9.99992 22.0002 9.33325 20.6668 9.33325H19.3335C18.0002 9.33325 17.3335 9.99992 17.3335 11.3333V12.6666C17.3335 13.9999 18.0002 14.6666 19.3335 14.6666Z"
          stroke="#CA2543"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3335 22.6666H20.6668C22.0002 22.6666 22.6668 21.9999 22.6668 20.6666V19.3333C22.6668 17.9999 22.0002 17.3333 20.6668 17.3333H19.3335C18.0002 17.3333 17.3335 17.9999 17.3335 19.3333V20.6666C17.3335 21.9999 18.0002 22.6666 19.3335 22.6666Z"
          fill="#CA2543"
          stroke="#CA2543"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3335 22.6666H12.6668C14.0002 22.6666 14.6668 21.9999 14.6668 20.6666V19.3333C14.6668 17.9999 14.0002 17.3333 12.6668 17.3333H11.3335C10.0002 17.3333 9.3335 17.9999 9.3335 19.3333V20.6666C9.3335 21.9999 10.0002 22.6666 11.3335 22.6666Z"
          fill="#CA2543"
          stroke="#CA2543"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function MyDeals() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill="#CA2543" fillOpacity="0.08" />
      <g opacity="0.5">
        <path
          d="M13.3047 16.5249C11.1405 17.4999 9.57107 19.5249 9.29688 21.9999C9.29688 22.8249 9.92155 23.4999 10.75 23.4999H21.25C22.0788 23.4999 22.7035 22.8249 22.7035 21.9999C22.429 19.5249 20.8592 17.4999 18.6955 16.5249C18.2087 17.4999 17.1872 18.2499 16 18.2499C14.8128 18.2499 13.791 17.4999 13.3047 16.5249Z"
          fill="#CA2543"
        />
        <path
          d="M19.75 12.25C19.75 13.2446 19.3549 14.1984 18.6517 14.9017C17.9484 15.6049 16.9946 16 16 16C15.0054 16 14.0516 15.6049 13.3483 14.9017C12.6451 14.1984 12.25 13.2446 12.25 12.25C12.25 11.2554 12.6451 10.3016 13.3483 9.59835C14.0516 8.89509 15.0054 8.5 16 8.5C16.9946 8.5 17.9484 8.89509 18.6517 9.59835C19.3549 10.3016 19.75 11.2554 19.75 12.25Z"
          fill="#CA2543"
        />
        <path
          d="M15.7031 16C14.7454 16 13.8324 16.2017 13.0078 16.5625C10.8436 17.509 9.2742 19.5602 9 22C9 22.8288 9.62468 23.5 10.4531 23.5H20.9531C21.7819 23.5 22.4066 22.8288 22.4066 22C22.1321 19.5602 20.5624 17.509 18.3986 16.5625C17.5736 16.2017 16.6609 16 15.7031 16Z"
          fill="#CA2543"
        />
      </g>
    </svg>
  );
}

export function NewDeals() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill="#CA2543" fillOpacity="0.08" />
      <g opacity="0.5">
        <path
          d="M17.7623 8.5H14.2373C13.4573 8.5 12.8198 9.13 12.8198 9.91V10.615C12.8198 11.395 13.4498 12.025 14.2298 12.025H17.7623C18.5423 12.025 19.1723 11.395 19.1723 10.615V9.91C19.1798 9.13 18.5423 8.5 17.7623 8.5Z"
          fill="#CA2543"
        />
        <path
          d="M19.9298 10.6151C19.9298 11.8076 18.9548 12.7826 17.7623 12.7826H14.2373C13.0448 12.7826 12.0698 11.8076 12.0698 10.6151C12.0698 10.1951 11.6198 9.93261 11.2448 10.1276C10.1873 10.6901 9.46729 11.8076 9.46729 13.0901V20.1476C9.46729 21.9926 10.9748 23.5001 12.8198 23.5001H19.1798C21.0248 23.5001 22.5323 21.9926 22.5323 20.1476V13.0901C22.5323 11.8076 21.8123 10.6901 20.7548 10.1276C20.3798 9.93261 19.9298 10.1951 19.9298 10.6151ZM18.5048 16.5476L15.5048 19.5476C15.3923 19.6601 15.2498 19.7126 15.1073 19.7126C14.9648 19.7126 14.8223 19.6601 14.7098 19.5476L13.5848 18.4226C13.3673 18.2051 13.3673 17.8451 13.5848 17.6276C13.8023 17.4101 14.1623 17.4101 14.3798 17.6276L15.1073 18.3551L17.7098 15.7526C17.9273 15.5351 18.2873 15.5351 18.5048 15.7526C18.7223 15.9701 18.7223 16.3301 18.5048 16.5476Z"
          fill="#CA2543"
        />
      </g>
    </svg>
  );
}

export function DocumentsIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill="#CA2543" fillOpacity="0.08" />
      <g opacity="0.5">
        <path
          d="M17.7623 8.5H14.2373C13.4573 8.5 12.8198 9.13 12.8198 9.91V10.615C12.8198 11.395 13.4498 12.025 14.2298 12.025H17.7623C18.5423 12.025 19.1723 11.395 19.1723 10.615V9.91C19.1798 9.13 18.5423 8.5 17.7623 8.5Z"
          fill="#CA2543"
        />
        <path
          d="M19.9298 10.6151C19.9298 11.8076 18.9548 12.7826 17.7623 12.7826H14.2373C13.0448 12.7826 12.0698 11.8076 12.0698 10.6151C12.0698 10.1951 11.6198 9.93261 11.2448 10.1276C10.1873 10.6901 9.46729 11.8076 9.46729 13.0901V20.1476C9.46729 21.9926 10.9748 23.5001 12.8198 23.5001H19.1798C21.0248 23.5001 22.5323 21.9926 22.5323 20.1476V13.0901C22.5323 11.8076 21.8123 10.6901 20.7548 10.1276C20.3798 9.93261 19.9298 10.1951 19.9298 10.6151ZM16.2848 19.7126H12.9998C12.6923 19.7126 12.4373 19.4576 12.4373 19.1501C12.4373 18.8426 12.6923 18.5876 12.9998 18.5876H16.2848C16.5923 18.5876 16.8473 18.8426 16.8473 19.1501C16.8473 19.4576 16.5923 19.7126 16.2848 19.7126ZM18.2498 16.7126H12.9998C12.6923 16.7126 12.4373 16.4576 12.4373 16.1501C12.4373 15.8426 12.6923 15.5876 12.9998 15.5876H18.2498C18.5573 15.5876 18.8123 15.8426 18.8123 16.1501C18.8123 16.4576 18.5573 16.7126 18.2498 16.7126Z"
          fill="#CA2543"
        />
      </g>
    </svg>
  );
}

export function LogoutIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="6" fill="#CA2543" fillOpacity="0.08" />
      <g opacity="0.5">
        <path
          d="M19.6 8.5H17.65C15.25 8.5 13.75 10 13.75 12.4V15.4375H18.4375C18.745 15.4375 19 15.6925 19 16C19 16.3075 18.745 16.5625 18.4375 16.5625H13.75V19.6C13.75 22 15.25 23.5 17.65 23.5H19.5925C21.9925 23.5 23.4925 22 23.4925 19.6V12.4C23.5 10 22 8.5 19.6 8.5Z"
          fill="#CA2543"
        />
        <path
          d="M10.4201 15.4374L11.9726 13.8849C12.0851 13.7724 12.1376 13.6299 12.1376 13.4874C12.1376 13.3449 12.0851 13.1949 11.9726 13.0899C11.7551 12.8724 11.3951 12.8724 11.1776 13.0899L8.66508 15.6024C8.44758 15.8199 8.44758 16.1799 8.66508 16.3974L11.1776 18.9099C11.3951 19.1274 11.7551 19.1274 11.9726 18.9099C12.1901 18.6924 12.1901 18.3324 11.9726 18.1149L10.4201 16.5624H13.7501V15.4374H10.4201Z"
          fill="#CA2543"
        />
      </g>
    </svg>
  );
}

export function AlbumIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5798 19.01L2.5598 19.03C2.2898 18.44 2.1198 17.77 2.0498 17.03C2.1198 17.76 2.3098 18.42 2.5798 19.01Z"
        fill="#292D32"
      />
      <path
        d="M9.00012 10.3801C10.3146 10.3801 11.3801 9.31456 11.3801 8.00012C11.3801 6.68568 10.3146 5.62012 9.00012 5.62012C7.68568 5.62012 6.62012 6.68568 6.62012 8.00012C6.62012 9.31456 7.68568 10.3801 9.00012 10.3801Z"
        fill="#292D32"
      />
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V13.9V7.81C22 4.17 19.83 2 16.19 2ZM20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16.19C19.01 3.5 20.5 4.99 20.5 7.81V12.61L20.37 12.5Z"
        fill="#292D32"
      />
    </svg>
  );
}

export function DownArrow() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2802 5.96655L8.93355 10.3132C8.42021 10.8266 7.58022 10.8266 7.06688 10.3132L2.72021 5.96655"
        stroke="#0E1C2C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NotificationBell() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22" cy="22" r="22" fill="#F9F9F9" />
      <path
        d="M29.3399 24.49L28.3399 22.83C28.1299 22.46 27.9399 21.76 27.9399 21.35V18.82C27.9399 16.47 26.5599 14.44 24.5699 13.49C24.0499 12.57 23.0899 12 21.9899 12C20.8999 12 19.9199 12.59 19.3999 13.52C17.4499 14.49 16.0999 16.5 16.0999 18.82V21.35C16.0999 21.76 15.9099 22.46 15.6999 22.82L14.6899 24.49C14.2899 25.16 14.1999 25.9 14.4499 26.58C14.6899 27.25 15.2599 27.77 15.9999 28.02C17.9399 28.68 19.9799 29 22.0199 29C24.0599 29 26.0999 28.68 28.0399 28.03C28.7399 27.8 29.2799 27.27 29.5399 26.58C29.7999 25.89 29.7299 25.13 29.3399 24.49Z"
        fill="#0E1C2C"
      />
      <path
        d="M24.8302 30.01C24.4102 31.17 23.3002 32 22.0002 32C21.2102 32 20.4302 31.68 19.8802 31.11C19.5602 30.81 19.3202 30.41 19.1802 30C19.3102 30.02 19.4402 30.03 19.5802 30.05C19.8102 30.08 20.0502 30.11 20.2902 30.13C20.8602 30.18 21.4402 30.21 22.0202 30.21C22.5902 30.21 23.1602 30.18 23.7202 30.13C23.9302 30.11 24.1402 30.1 24.3402 30.07C24.5002 30.05 24.6602 30.03 24.8302 30.01Z"
        fill="#0E1C2C"
      />
    </svg>
  );
}

export function ThreeDots() {
  return (
    <svg
      width="18"
      height="4"
      viewBox="0 0 18 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M9 3C9.55228 3 10 2.55228 10 2C10 1.44772 9.55228 1 9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3Z"
          stroke="#0E1C2C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 3C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1C15.4477 1 15 1.44772 15 2C15 2.55228 15.4477 3 16 3Z"
          stroke="#0E1C2C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z"
          stroke="#0E1C2C"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function UploadIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 8L12 3L7 8"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3V15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function UserIconFilled() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4062 10.7C2.5206 12 0.4281 14.7 0.0625 18C0.0625 19.1 0.8954 20 2 20H16C17.105 20 17.938 19.1 17.938 18C17.572 14.7 15.479 12 12.594 10.7C11.945 12 10.583 13 9 13C7.417 13 6.0547 12 5.4062 10.7Z"
        fill="#666666"
      />
      <path
        d="M14 5C14 6.32608 13.4732 7.59785 12.5355 8.53553C11.5979 9.47322 10.3261 10 9 10C7.67392 10 6.40215 9.47322 5.46447 8.53553C4.52678 7.59785 4 6.32608 4 5C4 3.67392 4.52678 2.40215 5.46447 1.46447C6.40215 0.526784 7.67392 0 9 0C10.3261 0 11.5979 0.526784 12.5355 1.46447C13.4732 2.40215 14 3.67392 14 5Z"
        fill="#666666"
      />
      <path
        d="M9 9C7.723 9 6.5057 9.269 5.4062 9.75C2.5206 11.012 0.4281 13.747 0.0625 17C0.0625 18.105 0.8954 19 2 19H16C17.105 19 17.938 18.105 17.938 17C17.572 13.747 15.479 11.012 12.594 9.75C11.494 9.269 10.277 9 9 9Z"
        fill="#666666"
      />
    </svg>
  );
}

export function MenuIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-menu"
      {...props}
    >
      <line x1="4" x2="20" y1="12" y2="12" />
      <line x1="4" x2="20" y1="6" y2="6" />
      <line x1="4" x2="20" y1="18" y2="18" />
    </svg>
  );
}
